import store from '@state/store'

export default [{
	path: '/',
	name: 'home',
	component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),
	meta: {
		authRequired: true,
		permissions: ['MARKETING_DASHBOARD_ENABLE']
	},

	// props: (route) => ({
	//   user: store.state.auth.currentUser || {}
	// }),
},
{
	path: '/login',
	name: 'login',
	component: () => lazyLoadView(import('@views/login')),
	meta: {
		beforeResolve(routeTo, routeFrom, next) {
			// If the user is already logged in
			if (store.getters['auth/loggedIn']) {
				// Redirect to the home page instead
				next({
					name: 'home'
				})
			} else {
				// Continue to the login page
				next()
			}
		},
	},
},
{
	path: '/register',
	name: 'register',
	component: () => lazyLoadView(import('@views/register')),
	meta: {
		beforeResolve(routeTo, routeFrom, next) {
			// If the user is already logged in
			if (store.getters['auth/loggedIn']) {
				// Redirect to the home page instead
				next({
					name: 'home'
				})
			} else {
				// Continue to the login page
				next()
			}
		},
	},
},
{
	path: '/confirm-account',
	name: 'confirm-account',
	component: () => lazyLoadView(import('@views/confirm')),
	meta: {
		beforeResolve(routeTo, routeFrom, next) {
			// If the user is already logged in
			if (store.getters['auth/loggedIn']) {
				// Redirect to the home page instead
				next({
					name: 'home'
				})
			} else {
				// Continue to the login page
				next()
			}
		},
	},
},
{
	path: '/forget-password',
	name: 'forget-password',
	component: () => lazyLoadView(import('@views/forgetPassword')),
	meta: {
		beforeResolve(routeTo, routeFrom, next) {
			// If the user is already logged in
			if (store.getters['auth/loggedIn']) {
				// Redirect to the home page instead
				next({
					name: 'home'
				})
			} else {
				// Continue to the login page
				next()
			}
		},
	},
},
{
	path: '/logout',
	name: 'logout',
	meta: {
		authRequired: false,
		beforeResolve(routeTo, routeFrom, next) {
			store.dispatch('auth/logOut')
			const authRequiredOnPreviousRoute = routeFrom.matched.some(
				(route) => route.meta.authRequired
			)
			// Navigate back to previous page, or home as a fallback
			next(authRequiredOnPreviousRoute ? {
				name: 'home'
			} : {
				...routeFrom
			})
		},
	},
},
{
	path: '/404',
	name: '404',
	component: require('@views/_404').default,
	// Allows props to be passed to the 404 page through route
	// params, such as `resource` to define what wasn't found.
	props: true,
},
// Redirect any unmatched routes to the 404 page. This may
// require some server configuration to work in production:
// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
{
	path: '*',
	redirect: '404',
},
{
	path: '/quotation',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['QUOTATION_ENABLE']
	},
	children: [{
		path: '',
		name: 'Quotation List',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationList.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_ENABLE']

		},
	},
	{
		path: 'create',
		name: 'Quotation Create',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationCreate.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'Quotation Edit',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'Quotation View',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_VIEW']
		},
	}
	]
},
{
	path: '/best-customer',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['QUOTATION_ENABLE']

	},
	children: [{
		path: '',
		name: 'Best Customer',
		component: () => lazyLoadView(import('@views/Admin/BestCustomer/BestCustomerList.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_ENABLE']
		},
	},
	{
		path: 'create',
		name: 'Quotation Create',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationCreate.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'Quotation Edit',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'Quotation View',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_VIEW']
		},
	}
	]
},
{
	path: '/report-listsparepart',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['QUOTATION_ENABLE']

	},
	children: [{
		path: '',
		name: 'Report List Sparepart',
		component: () => lazyLoadView(import('@views/Admin/ReportListSparepart/ReportListSparepartList.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_ENABLE']
		},
	},
	{
		path: 'create',
		name: 'Quotation Create',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationCreate.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'Quotation Edit',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'Quotation View',
		component: () => lazyLoadView(import('@views/Admin/Quotation/QuotationDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_VIEW']
		},
	}
	]
},
{
	path: '/leads-customer',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['CONTACT_MANAGEMENT_ENABLE']
	},
	children: [
		{
			path: '',
			name: 'ContactManagement List',
			component: () => lazyLoadView(import('@views/Admin/ContactManagement/ContactManagementList.vue')),
			meta: {
				authRequired: true,
				permissions: ['CONTACT_MANAGEMENT_ENABLE']
			},
		},
		{
			path: 'board',
			name: 'ContactManagement Board',
			component: () => lazyLoadView(import('@views/Admin/ContactManagement/ContactManagementBoard.vue')),
			meta: {
				authRequired: true,
				permissions: ['CONTACT_MANAGEMENT_ENABLE']
			},
		},
		// {
		//   path: 'create',
		//   name: 'ContactManagement Create',
		//   component: () => lazyLoadView(import('@views/Admin/ContactManagement/ContactManagementCreate.vue'))
		// },
		// {
		//   path: 'edit/:id',
		//   name: 'ContactManagement Edit',
		//   component: () => lazyLoadView(import('@views/Admin/ContactManagement/ContactManagementEdit.vue'))
		// },
		// {
		//   path: 'detail/:id',
		//   name: 'ContactManagement Detail',
		//   component: () => lazyLoadView(import('@views/Admin/ContactManagement/ContactManagementDetail.vue'))
		// }
	]
},
{
	path: '/job-order',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['TMS_JOB_ORDER_ENABLE']

	},
	children: [{
		path: '/',
		name: 'Job Order',
		component: () => lazyLoadView(import('@views/Admin/JobOrder/JobOrderList.vue')),
		meta: {
			authRequired: true,
			permissions: ['TMS_JOB_ORDER_ENABLE']
		},

	},
	{
		path: 'details/:id',
		name: 'JobOrder Details',
		component: () => lazyLoadView(import('@views/Admin/JobOrder/JobOrderDetails.vue')),
		meta: {
			// authRequired: true,
			// permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: ':id/add-distribution',
		name: 'JobOrder Add Distribution',
		component: () => lazyLoadView(import('@views/Admin/JobOrder/Distribution/AddDistribution.vue')),
		meta: {
			// authRequired: true,
			// permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: 'edit-distribution/:id',
		name: 'JobOrder Edit Distribution',
		component: () => lazyLoadView(import('@views/Admin/JobOrder/Distribution/AddDistribution.vue')),
		meta: {
			// authRequired: true,
			// permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: 'detail-distribution/:id',
		name: 'JobOrder Detail Distribution',
		component: () => lazyLoadView(import('@views/Admin/JobOrder/Distribution/DetailDistribution.vue')),
		meta: {
			// authRequired: true,
			// permissions: ['QUOTATION_UPDATE']
		},
	},
	{
		path: 'delivery-letter',
		name: 'JobOrder Delivery Letter',
		component: () => lazyLoadView(import('@views/Admin/JobOrder/Distribution/DeliveryLetter.vue')),
		meta: {
			// authRequired: true,
			// permissions: ['QUOTATION_UPDATE']
		},
	},
	]
},
{
	path: '/tracking-shipment',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['TRACKING_SHIPMENT_ENABLE']
	},
	children: [
		{
			path: '/',
			name: 'Tracking Shipment',
			component: () => lazyLoadView(import('@views/Admin/TrackingShipment/TrackingShipment.vue')),
			// meta: {
			// 	authRequired: true,
			// 	permissions: ['TMS_JOB_ORDER_ENABLE']
			// },
		},
		{
			path: '/view-timeline/:id',
			name: 'View Timeline',
			component: () => lazyLoadView(import('@views/Admin/TrackingShipment/ViewTimeline.vue')),
		},
	]
},
{
	path: '/reason',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['REASON_ENABLE']
	},
	children: [{
		path: '',
		name: 'Reason List',
		component: () => lazyLoadView(import('@views/Admin/Reason/ReasonList.vue')),
		meta: {
			authRequired: true,
			permissions: ['REASON_ENABLE']
		},
	},
		// {
		//   path: 'create',
		//   name: 'Reason Create',
		// 	component: () => lazyLoadView(import('@views/Admin/Reason/ReasonCreate.vue')),
		// 	meta: {
		// 		authRequired: true,

		// 	},
		// },
		// {
		//   path: 'edit/:id',
		//   name: 'Reason Edit',
		// 	component: () => lazyLoadView(import('@views/Admin/Reason/ReasonEdit.vue')),
		// 	meta: {
		// 		authRequired: true,

		// 	},
		// }
	]
},
{
	path: '/check_logout',
	name: 'check_logout',
	beforeEnter: () => {
		window.localStorage.removeItem('access_token')
		window.localStorage.removeItem('userPermissions')
		window.localStorage.removeItem('company')
		window.localStorage.removeItem('user')
		window.localStorage.removeItem('name')
		window.localStorage.removeItem('logo')
		window.localStorage.removeItem('default_branch')
		window.localStorage.removeItem('list_branch')
		window.location.href = process.env.VUE_APP_CI_URL + '/admin'
	}
},
{
	path: '/check',
	name: 'check',
	beforeEnter: () => {
		window.location.href = process.env.VUE_APP_CI_URL + '/admin/dashboard_ops'
	}
},
{
	path: '/report-ontime-delay',
	name: 'report_ontime_delay',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['QUOTATION_ENABLE']

	},
	children: [{
		path: '',
		name: 'Report Ontime Delay',
		component: () => lazyLoadView(import('@views/Admin/Report/ReportOntime.vue')),
		meta: {
			authRequired: true,
			permissions: ['QUOTATION_ENABLE']

		},
	}],
},
{
	path: '/report-list-transaction',
	name: 'report_list_transaction',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	// meta: {
	// 	authRequired: true,
	// 	permissions: ['LIST_TRANSACTION_ENABLE']

	// },
	children: [{
		path: '',
		name: 'Report Ontime Delay',
		component: () => lazyLoadView(import('@views/Admin/Report/ReportListTransaction.vue')),
		// meta: {
		// 	authRequired: true,
		// 	permissions: ['LIST_TRANSACTION_ENABLE']

		// },
	}],
},
{
	path: '/MultiBuyingDownPayment',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['CASH_ADVANCE_ENABLE']
	},
	children: [{
		path: '',
		name: 'MultiBuyingDownPayment List',
		component: () => lazyLoadView(import('@views/Admin/MultiBuyingDownPayment/MultiBuyingDownPaymentList.vue')),
		meta: {
			authRequired: true,
			permissions: ['CASH_ADVANCE_ENABLE']

		},
	},
	{
		path: 'DetailPI/:id',
		name: 'MultiBuyingDownPayment Detail PI',
		component: () => lazyLoadView(import('@views/Admin/MultiBuyingDownPayment/BuyingList.vue')),
		meta: {
			authRequired: true,
			permissions: ['CASH_ADVANCE_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'MultiBuyingDownPayment Edit',
		component: () => lazyLoadView(import('@views/Admin/MultiBuyingDownPayment/MultiBuyingDownPaymentEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['CASH_ADVANCE_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'MultiBuyingDownPayment View',
		component: () => lazyLoadView(import('@views/Admin/MultiBuyingDownPayment/MultiBuyingDownPaymentDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['CASH_ADVANCE_VIEW']
		},
	}
	],


},
{
	path: '/ReceivableItem',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['ACCRUAL_COST_STEP_2_ENABLE']
	},
	children: [{
		path: '',
		name: 'ReceivableItem List',
		component: () => lazyLoadView(import('@views/Admin/ReceivableItem/ReceivableItemList.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_2_ENABLE']

		},
	},
	{
		path: 'DetailPI/:id',
		name: 'ReceivableItem Detail PI',
		component: () => lazyLoadView(import('@views/Admin/ReceivableItem/BuyingList.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_2_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'ReceivableItem Edit',
		component: () => lazyLoadView(import('@views/Admin/ReceivableItem/ReceivableItemEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_2_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'ReceivableItem Detail',
		component: () => lazyLoadView(import('@views/Admin/ReceivableItem/ReceivableItemDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_2_ENABLE']
		},
	},
	{
		path: 'detail-applied-transaction/:id',
		name: 'ReceiveableItem Detail PI',
		component: () => lazyLoadView(import('@views/Admin/ReceivableItem/BuyingList.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_2_CREATE']
		},
	}
	],


},

{
	path: '/purchase-order',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['ACCRUAL_COST_STEP_1_ENABLE']
	},
	children: [{
		path: '',
		name: 'PurchaseOrder List',
		component: () => lazyLoadView(import('@views/Admin/PurchaseOrder/PurchaseOrderList.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_1_ENABLE']

		},
	},
	{
		path: 'detail/:id',
		name: 'MultiBuyingDownPayment Detail PI',
		component: () => lazyLoadView(import('@views/Admin/PurchaseOrder/PurchaseOrderDetail.vue')),
		// meta: {
		// 	authRequired: true,
		// 	permissions: ['ACCRUAL_COST_STEP_1_CREATE']
		// },
	},
	{
		path: 'detail-applied-transaction/:id',
		name: 'PurchaseOrder Detail PI',
		component: () => lazyLoadView(import('@views/Admin/PurchaseOrder/BuyingList.vue')),
		meta: {
			authRequired: true,
			permissions: ['ACCRUAL_COST_STEP_1_CREATE']
		},
	},
		// {
		// 	path: 'edit/:id',
		// 	name: 'MultiBuyingDownPayment Edit',
		// 	component: () => lazyLoadView(import('@views/Admin/MultiBuyingDownPayment/MultiBuyingDownPaymentEdit.vue')),
		// 	meta: {
		// 		authRequired: true,
		// 		permissions: ['PURCHASE_ORDER_UPDATE']
		// 	},
		// },
		// {
		// 	path: 'detail/:id',
		// 	name: 'MultiBuyingDownPayment View',
		// 	component: () => lazyLoadView(import('@views/Admin/MultiBuyingDownPayment/MultiBuyingDownPaymentDetail.vue')),
		// 	meta: {
		// 		authRequired: true,
		// 		permissions: ['PURCHASE_ORDER_VIEW']
		// 	},
		// },



	]

},

{
	path: '/MultiPurchaseInvoice',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['VENDOR_INVOICE_ENABLE']
	},
	children: [{
		path: '',
		name: 'MultiPurchaseInvoice List',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/MultiPurchaseInvoiceList.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_ENABLE']

		},
	},
	{
		path: 'DetailPI/:id',
		name: 'MultiPurchaseInvoice Detail PI',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/BuyingList.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'MultiPurchaseInvoice Edit',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/MultiPurchaseInvoiceEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'MultiPurchaseInvoice View',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/MultiPurchaseInvoiceDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_VIEW']
		},
	}
	]

},
{
	path: '/MultiPurchaseInvoice',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['VENDOR_INVOICE_ENABLE']
	},
	children: [{
		path: '',
		name: 'MultiPurchaseInvoice List',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/MultiPurchaseInvoiceList.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_ENABLE']

		},
	},
	{
		path: 'DetailPI/:id',
		name: 'MultiPurchaseInvoice Detail PI',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/BuyingList.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_CREATE']
		},
	},
	{
		path: 'edit/:id',
		name: 'MultiPurchaseInvoice Edit',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/MultiPurchaseInvoiceEdit.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_UPDATE']
		},
	},
	{
		path: 'detail/:id',
		name: 'MultiPurchaseInvoice View',
		component: () => lazyLoadView(import('@views/Admin/MultiPurchaseInvoice/MultiPurchaseInvoiceDetail.vue')),
		meta: {
			authRequired: true,
			permissions: ['VENDOR_INVOICE_VIEW']
		},
	}
	]

},
{
	path: '/report-espr',
	name: 'report_worksheet',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	// meta: {
	// 	authRequired: true,
	// 	permissions: ['LIST_TRANSACTION_ENABLE']

	// },
	children: [{
		path: '',
		name: 'Report Worksheet',
		component: () => lazyLoadView(import('@views/Admin/ReportWorksheet/ReportWorksheet.vue')),
		// meta: {
		// 	authRequired: true,
		// 	permissions: ['REPORT_WORKSHEET_ENABLE']

		// },
	}],
},
{
	path: '/report-ebilling',
	name: 'report_ebilling',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	// meta: {
	// 	authRequired: true,
	// 	permissions: ['LIST_TRANSACTION_ENABLE']

	// },
	children: [{
		path: '',
		name: 'Report Ebilling',
		component: () => lazyLoadView(import('@views/Admin/ReportEbilling/ReportEbilling.vue')),
		// meta: {
		// 	authRequired: true,
		// 	permissions: ['REPORT_WORKSHEET_ENABLE']

		// },
	}],
},

{
	path: '/shipment-access',
	component: () => lazyLoadView(import('@views/Admin/AdminLayout.vue')),
	meta: {
		authRequired: true,
		permissions: ['SHIPMENT_ACCESS_ENABLE']
	},
	children: [{
		path: '',
		name: 'Shipment Access List',
		component: () => lazyLoadView(import('@views/Admin/TrackingShipment/ShipmentAccess/ShipmentAccessList.vue')),
		meta: {
			authRequired: true,
			permissions: ['SHIPMENT_ACCESS_ENABLE']

		},
	},
	]

},
{
	path: process.env.VUE_APP_CI_URL + '/dashboard_ops',
	name: 'dashboard',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/transaction_copy',
	name: 'transaction_copy',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/jobfile',
	name: 'jobfile',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/other_payment',
	name: 'other_payment',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/journal_voucher',
	name: 'journal_voucher',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/report_jobfile',
	name: 'report_jobfile',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/report_lifting',
	name: 'report_lifting',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/report_logistic',
	name: 'report_logistic',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/report_harian',
	name: 'report_harian',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_agent',
	name: 'master_agent',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_airline',
	name: 'master_airline',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_autonumber',
	name: 'master_autonumber',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_branch',
	name: 'master_branch',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},
{
	path: process.env.VUE_APP_CI_URL + '/master_currency',
	name: 'master_currency',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_customer',
	name: 'master_customer',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_depo',
	name: 'master_depo',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_driver',
	name: 'master_driver',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_item',
	name: 'master_item',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_port',
	name: 'master_port',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_shipping',
	name: 'master_shipping',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_term',
	name: 'master_term',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_truck',
	name: 'master_truck',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_vendor',
	name: 'master_vendor',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/master_warehouse',
	name: 'master_warehouse',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/aol_access',
	name: 'aol_access',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/group_access',
	name: 'group_access',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/user',
	name: 'user',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/activity_log',
	name: 'activity_log',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},

{
	path: process.env.VUE_APP_CI_URL + '/setting_email',
	name: 'setting_email',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},
{
	path: process.env.VUE_APP_CI_URL + '/setting-job-order',
	name: 'setting_job_order',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},
{
	path: process.env.VUE_APP_CI_URL + '/list_sparepart',
	name: 'list_sparepart',
	// component: () => lazyLoadView(import( /* webpackChunkName: "dashboard" */ '@views/home.vue')),

},


]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
	const AsyncHandler = () => ({
		component: AsyncView,
		// A component to use while the component is loading.
		loading: require('@views/_loading').default,
		// Delay before showing the loading component.
		// Default: 200 (milliseconds).
		delay: 200,
		// A fallback component in case the timeout is exceeded
		// when loading the component.
		error: require('@views/_timeout').default,
		// Time before giving up trying to load the component.
		// Default: Infinity (milliseconds).
		timeout: 10000,
	})

	return Promise.resolve({
		functional: true,
		render(h, {
			data,
			children
		}) {
			// Transparently pass any props or children
			// to the view component.
			return h(AsyncHandler, data, children)
		},
	})
}
